import axios from 'axios'

import securityUtils from '@/utils/security'

const ERROR_MAP = {
    400: 'Please enter all required fields',
    401: 'Login session expired',
    403: 'Access denied',
    404: 'Resource not found',
    405: 'Method not allowed',
    409: 'An account with this email address already exists',
    500: 'System error, please try again or contact an official for assistance',
    503: 'Could not connect API server'
}

const API_URL=process.env.VUE_APP_API_URL
const AUTH_HEADER_TYPE = 'Bearer'

/**
 * Intercept request
 * - Inject authorization header if authToken is available
 */
axios.interceptors.request.use(function(config) {
    let authToken = securityUtils.getAuthToken()
    if (authToken !== null) {
        config.headers.common['Authorization'] = `${AUTH_HEADER_TYPE} ${authToken}`
    }
    return config
})

/**
 * Intercept response
 * - Handle errors
 */
axios.interceptors.response.use(function (response) {
    return response
}, function (error) {

    if (!error.response || !error.response.status) {
        return Promise.reject(error)
    }

    let statusCode = error.response.status
    let pathName = window.location.pathname

    switch (statusCode) {
        case 401:
            if (pathName === '/login') {
                return Promise.reject(error)
            }
            window.location = `/login?errorCode=${statusCode}`
            break

        case 403:
            if (securityUtils.isLoggedIn()) {
                return Promise.reject(error)
            }
            window.location = `/login?errorCode=${statusCode}`
            break

        case 400:
        case 404:
        case 409:
            return Promise.reject(error)

        default:
            window.location = `/error?errorCode=${statusCode}`
            break
    }
})


export default {

    getErrorMap() {
        return ERROR_MAP
    },

    getErrorMessageByCode(errorCode) {
      return ERROR_MAP[errorCode]
    },

    /**
     * Parse API response error
     * @param error
     * @param getRawMessage
     * @return {*}
     */
    parseResponseError(error, getRawMessage=false) {
        if (error.response === undefined) {
            return ERROR_MAP[500]
        }

        if (getRawMessage && error.response.data.message !== undefined) {
            return error.response.data.message
        }

        let statusCode = error.response.status.toString()
        return ERROR_MAP[statusCode] || error.response.data.message
    },

    /**
     * Authenticate API
     * @param username
     * @param password
     * @return {Promise<AxiosResponse<any>>}
     */
    authenticate(username, password) {
        let payload = {
            email: username,
            password: password
        }
        return axios.post(`${API_URL}/auth/token`, payload)
    },

    /**
     * Make crate user POST call
     * @param payload
     */
    createUser(payload) {
        return axios.post(`${API_URL}/users`, payload)
    },

    /**
     * Make update user PUT call
     * @param id
     * @param payload
     */
    updateUser(id, payload) {
        return axios.put(`${API_URL}/users/${id}`, payload)
    },

    /**
     * Make set user status PUT call
     * @param id
     * @param payload
     */
    setUserStatus(id, payload) {
        return axios.put(`${API_URL}/users/${id}/status`, payload)
    },

    /**
     * Make set user roles PUT call
     * @param id
     * @param payload
     */
    setUserRoles(id, payload) {
        return axios.put(`${API_URL}/users/${id}/roles`, payload)
    },

    /**
     * Make update password PUT call
     * @param id
     * @param payload
     */
    updatePassword(id, payload) {
        return axios.put(`${API_URL}/users/${id}/password`, payload)
    },

    /**
     * Make password reset POST call
     * @param payload
     */
    generateResetPassword(payload) {
        return axios.post(`${API_URL}/passwordreset/generate`, payload)
    },


    /**
     * Make generate password reset POST call
     * @param token
     * @param payload
     */
    resetPassword(payload) {
        return axios.post(`${API_URL}/passwordreset/reset`, payload)
    },

    /**
     * Fetch users
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchUsers() {
        return axios.get(`${API_URL}/users`)
    },


    /**
     * Fetch a user by id
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchUser(id) {
        return axios.get(`${API_URL}/users/${id}`)
    },

    /**
     * Get admin review Users
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchReviewMembers(criteria) {
        return axios.get(`${API_URL}/users/adminreview`)
    },

    /**
     * Fetch relation types
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchRelationTypes() {
        return axios.get(`${API_URL}/relationtypes`)
    },

    /**
     * Fetch a user dependents
     * @param userId
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchDependents(userId) {
        return axios.get(`${API_URL}/users/${userId}/dependents`)
    },

    /**
     * Add dependent to user
     * @param userId
     * @param payload
     */
    addDependent(userId, payload) {
        return axios.post(`${API_URL}/users/${userId}/dependents`, payload)
    },

    /**
     * Fetch a user dependent
     * @param userId
     * @param dependentId
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchDependent(userId, dependentId) {
        return axios.get(`${API_URL}/users/${userId}/dependents/${dependentId}`)
    },

    /**
     * Update dependent PUT action
     * @param userId
     * @param dependentId
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateDependent(userId, dependentId, payload) {
        return axios.put(`${API_URL}/users/${userId}/dependents/${dependentId}`, payload)
    },

    /**
     * Fetch review dependents
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchReviewDependents() {
        return axios.get(`${API_URL}/dependents/adminreview`)
    },


    /**
     * Fetch IdTypes
     * @return {Promise<AxiosResponse<any>>}
     */
    fetchIdTypes() {
        return axios.get(`${API_URL}/idtypes`)
    },

    /**
     * Fetch Qualifications
     * @return {Promise<AxiosResponse<any>>}
     */
    fetchQualifications() {
        return axios.get(`${API_URL}/users/qualifications`)
    },

    /**
     * Add claim to user
     * @param userId
     * @param payload
     */
    addClaim(userId, payload) {
        return axios.post(`${API_URL}/users/${userId}/claims`, payload)
    },

    /**
     * Fetch a user claims
     * @param userId
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchUserClaims(userId) {
        return axios.get(`${API_URL}/users/${userId}/claims`)
    },

    /**
     * Fetch a user claim
     * @param userId
     * @param claimId
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchClaim(userId, claimId) {
        return axios.get(`${API_URL}/users/${userId}/claims/${claimId}`)
    },

    /**
     * Update claim PUT action
     * @param userId
     * @param claimId
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateClaim(userId, claimId, payload) {
        return axios.put(`${API_URL}/users/${userId}/claims/${claimId}/manage`, payload)
    },

    /**
     * Fetch claims
     * @param userId
     * @param claimId
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchClaims() {
        return axios.get(`${API_URL}/claims`)
    },

    /**
     * Fetch admin review claims
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchReviewClaims() {
        return axios.get(`${API_URL}/claims/adminreview`)
    },

    fetchReviewPayments() {
        return axios.get(`${API_URL}/invoices/adminreview`)
    },


    /**
     * Create bulk invoices
     *
     * @param userId
     * @param payload
     */
    createInvoice(userId, payload) {
        return axios.post(`${API_URL}/users/${userId}/invoices`, payload)
    },

    /**
     * Create bulk invoices
     *
     * @param payload
     */
    createBulkInvoices(payload) {
        return axios.post(`${API_URL}/invoices/bulk`, payload)
    },

    /**
     * Fetch claim replenishment invoices
     *
     * @param claimId
     */
    fetchClaimInvoices(claimId) {
        return axios.get(`${API_URL}/claims/${claimId}/invoices`)
    },

    /**
     * Fetch user invoices
     *
     * @param userId
     */
    fetchUserInvoices(userId) {
        return axios.get(`${API_URL}/users/${userId}/invoices`)
    },

    /**
     * Fetch Invoice
     *
     * @param invoiceId
     */
    fetchInvoice(invoiceId) {
        return axios.get(`${API_URL}/invoices/${invoiceId}`)
    },


    /**
     * Create payment for invoice
     *
     * @param invoiceId
     * @param payload
     */
    createPayment(invoiceId, payload) {
        return axios.post(`${API_URL}/invoices/${invoiceId}/payments`, payload)
    },

    /**
     * Set invoice status
     *
     * @param invoiceId
     * @param payload
     */
    setInvoiceStatus(invoiceId, payload) {
        return axios.put(`${API_URL}/invoices/${invoiceId}/status`, payload)
    },

    /**
     * Search invoices
     *
     * @param payload
     */
    searchInvoices(payload) {
        return axios.post(`${API_URL}/invoices/search`, payload)
    },

    fetchInvoicesByStatuses(payload) {
        return axios.post(`${API_URL}/invoices/status`, payload)
    },


    fetchPayment(invoiceId, paymentId) {
        return axios.get(`${API_URL}/invoices/${invoiceId}/payments/${paymentId}`)
    },

    updatePayment(invoiceId, paymentId, payload) {
        return axios.put(`${API_URL}/invoices/${invoiceId}/payments/${paymentId}`, payload)
    },


    fetchAuditLogs(itemId) {
        return axios.get(`${API_URL}/audits/${itemId}`)
    },

    /**
     * Fetch user recommenders
     * @param userId
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    createRecommender(userId, payload) {
        return axios.post(`${API_URL}/users/${userId}/recommenders`, payload)
    },

    /**
     * Fetch user recommenders
     * @param userId
     * @param recId
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateRecommender(userId, recId, payload) {
        return axios.put(`${API_URL}/users/${userId}/recommenders/${recId}`, payload)
    },

    /**
     * Fetch user recommenders
     * @param userId
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchRecommenders(userId) {
        return axios.get(`${API_URL}/users/${userId}/recommenders`)
    }
}


